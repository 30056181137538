import React, { useRef } from "react";
import { Typography, Grid, Divider, Popper, Fade, Paper, Container, Box } from "@mui/material";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
// import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

// Local Imports
import Heading from "components/HeadingComponent/Heading";
import RequestOverviewData from "components/RequestOverviewData/RequestOverviewData";
import ActiveAssetPdf from "components/PdfGenerator/PdfScreen/ActiveAssetPdf";

function DetailAsset({ asset, handleClickpop, openCopyPopup, anchorCopy }) {
  const Pdfref = useRef(null);
  const os = navigator.userAgent;
  // const printDocument = () => {
  //   if (typeof Pdfref.current) {
  //     const { chieldpdfRef, parentref } = Pdfref.current;
  //     parentref.current.style.display = "block";
  //     html2canvas(chieldpdfRef.current, {}).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       let pdf = jsPDF("p", "in", "a3");
  //       if (os.search("Mac") !== -1) {
  //         pdf = jsPDF("p", "pt", "a1", true);
  //       }
  //       pdf.addImage(imgData, "PNG", 0, 0);
  //       pdf.save("Asset_Detail.pdf");
  //     });
  //     parentref.current.style.display = "none";
  //   }
  // };

  let finalOs;
  if (os.search("Windows") !== -1) {
    finalOs = "Windows";
  }

  return (
    <>
      {asset !== null && <ActiveAssetPdf ref={Pdfref} assetDetail={asset} />}

      <Container>
        <Box>
          <Grid container>
            <Grid item md={7} pr={2}>
              <Grid container id="downloadcontent" ml={2} mt={3.7} className="dwcontent">
                {asset.reference_entities != null &&
                  asset.reference_entities.map((childAsset, index) => (
                    <Grid container spacing={1}>
                      <Grid item xs={2.1}>
                        <Typography
                          sx={{
                            fontFamily: "GothamBold",
                            color: " #4a4a4a",
                            fontSize: "12px",
                            // cursor: "pointer",
                            paddingTop: "1px",
                            lineHeight: "1.5",
                            letterSpacing: " 0.00938em",
                            paddingLeft: finalOs !== "Windows" && 1.8,
                          }}>
                          {childAsset?.updated_date}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <CircleIcon
                          className="mycircle"
                          sx={{
                            fontSize: "13px",
                            color: `${childAsset.color}`,
                            zIndex: 1,
                          }}
                        />
                      </Grid>

                      <Grid item xs={8.9}>
                        <Typography
                          sx={
                            index === 0
                              ? {
                                  fontFamily: "GothamBold",
                                  fontSize: "16px",
                                  paddingTop: "1px",
                                  color: "#262262",
                                  letterSpacing: "0.00938em ",
                                  lineHeight: "16px",
                                  fontWeight: "bold",
                                }
                              : {
                                  fontFamily: "GothamMedium",
                                  fontSize: "14px",
                                  paddingTop: "1px",
                                  color: "#262262",
                                  letterSpacing: "-0.013em ",
                                  lineHeight: "16px",
                                  fontWeight: "500",
                                }
                          }>
                          {childAsset?.custom_status_label}{" "}
                          <span className="b-asset-detail-view-container__body-assets__asset-day">
                            ({childAsset?.days}d)
                          </span>
                        </Typography>
                      </Grid>

                      <Box sx={finalOs === "Windows" ? { width: "20.5%" } : { width: "19.6%" }} />

                      {index + 1 !== asset?.reference_entities.length ? (
                        <Box
                          className="b-asset-detail-view-container__body-assets__indicator-divider"
                          sx={{
                            marginBottom: "-3px",
                            marginTop:
                              index === 0 && childAsset?.custom_status_label.length > 32
                                ? "-8px"
                                : "-7px",
                          }}
                        />
                      ) : null}

                      <Grid
                        item
                        xs={7.8}
                        sx={finalOs === "Windows" ? { marginLeft: "4%" } : { marginLeft: "5%" }}
                        className={
                          index + 1 === asset?.reference_entities.length &&
                          "b-asset-detail-view-container__last-description"
                        }>
                        <Typography
                          sx={{
                            fontFamily: "GothamBookItalic",
                            fontSize: "12px ",
                            marginTop: "-9px",
                            marginBottom: "20px",
                          }}
                          className="b-asset-detail-view-container__body-assets__asset-status-description">
                          {childAsset?.status_description}
                        </Typography>
                      </Grid>
                      <Grid xs={1} />
                    </Grid>
                  ))}

                {asset.reference_entities && asset.reference_entities.length === 0 && (
                  <>
                    <Grid item md={1.5} mt="-3px" mr="16px">
                      <Typography
                        sx={{
                          fontFamily: "GothamBold",
                          color: "#4a4a4a",
                          fontSize: "12px",
                          cursor: "pointer",
                          paddingTop: "1px",
                          lineHeight: "1.5",
                          letterSpacing: " 0.00938em",
                        }}>
                        {asset.updated_date}
                      </Typography>
                    </Grid>

                    <Grid item md={0.38}>
                      <CircleIcon
                        className="mycircle"
                        sx={{
                          fontSize: "13px",
                          color: `${asset.color}`,
                          zIndex: 1,
                        }}
                      />
                    </Grid>

                    <Grid item md={9} mt="-2px" ml="16px">
                      <Typography
                        sx={{
                          fontFamily: "GothamMedium",
                          fontSize: "14px",
                          paddingTop: "1px",
                          color: "#262262",
                          letterSpacing: "-0.013em ",
                          lineHeight: "16px",
                          fontWeight: "500",
                        }}>
                        {asset.custom_status_label}{" "}
                        <span className="b-asset-detail-view-container__body-assets__asset-day">
                          ({asset.days_till_today}d)
                        </span>
                      </Typography>
                    </Grid>

                    <Grid item md={2} />

                    <Grid item className="mycontainer" md={0.18} />

                    <Grid item md={9} ml={1.5} mt="2px" pb={2}>
                      <Typography
                        sx={{
                          fontFamily: "GothamBookItalic",
                          fontSize: "12px ",
                          paddingLeft: "6px",
                        }}
                        className="b-asset-detail-view-container__body-assets__asset-status-description">
                        {asset.status_description}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginTop: "1.8rem", border: "solid 1px #d5cccc" }}
            />

            <Grid item mt={3.7} md={4.5}>
              <Grid container id="accordian2">
                <Grid item md={12}>
                  <Grid container ml={3.5}>
                    {/* <Grid
                      onClick={printDocument}
                      item
                      md={1}
                      lg={4}
                      sx={{
                        color: "#216fb4",
                        alignItems: "center",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <SaveAltSharpIcon />
                      <Typography
                        ml="4px"
                        sx={{
                          fontSize: 10,
                          fontWeight: "bold",
                          marginLeft: "2px",
                          fontFamily: "GothamBook",
                        }}
                        className="b-asset-detail-view-container__asset-over-view-section__copy-link">
                        PDF Screenshot
                      </Typography>
                    </Grid> */}

                    <Grid
                      item
                      md={2.9}
                      lg={finalOs === "Windows" ? 2.7 : 2.1}
                      sx={{
                        color: "#216fb4",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleClickpop}>
                      <Popper
                        className="b-asset-detail-view-container__asset-over-view-section__sucess-popper"
                        open={openCopyPopup}
                        anchorEl={anchorCopy}
                        placement="top-start"
                        transition>
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Grid container>
                              <Grid item md={12}>
                                <Paper className="b-asset-detail-view-container__asset-over-view-section__popper-paper">
                                  <Grid container>
                                    <Grid item md={3.2} pr={0.5} pt={1} pb={1} pl={1}>
                                      <CheckCircleIcon
                                        sx={{
                                          color: "white",
                                          fontSize: 40,
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.8}
                                      pr={1}
                                      pt={1}
                                      pb={1}
                                      className="b-asset-detail-view-container__asset-over-view-section__text-align-left">
                                      <Typography
                                        sx={{
                                          fontSize: 13,
                                          fontWeight: "bold",
                                        }}
                                        className="b-asset-detail-view-container__asset-over-view-section__success">
                                        Success!
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                        }}
                                        className="b-asset-detail-view-container__asset-over-view-section__link-copid">
                                        Link copied to clipboard.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Fade>
                        )}
                      </Popper>

                      <ContentCopySharpIcon />

                      <Typography
                        sx={{
                          fontSize: 10,
                          fontWeight: "bold",
                          fontFamily: "GothamBook",
                          marginLeft: 0.5,
                        }}
                        className="b-asset-detail-view-container__asset-over-view-section__copy-link">
                        COPY LINK
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container ml={2}>
                  <Grid item md={12} pt={5} pb={1.25}>
                    <Heading
                      title="Asset Overview"
                      variant="body"
                      titleStyle="b-asset-detail-view-container__asset-over-view-section__asset-over-view-heading"
                    />
                  </Grid>

                  <RequestOverviewData
                    label="Type"
                    value={asset.type == null ? "---" : asset.type}
                  />
                  <RequestOverviewData label="Format" value={asset.format} />

                  <Grid item md={12} pb={1.25} pt={3.25}>
                    <Heading
                      title="Request Overview"
                      variant="body"
                      titleStyle="b-asset-detail-view-container__asset-over-view-section__asset-over-view-heading"
                    />
                  </Grid>

                  <RequestOverviewData label="Requester" value={asset.requester} />
                  <RequestOverviewData label="Affiliation" value={asset.affiliation} />
                  <RequestOverviewData label="Partner" value={asset.partner} space={2.5} />

                  <Divider sx={{ width: "100%", height: "1px", marginBottom: 2.39 }} />

                  <RequestOverviewData label="Request Type" value={asset.request_type} />
                  <RequestOverviewData label="Request ID" value={asset.wrike_task_id} space={2.5} />

                  <Divider sx={{ width: "100%", height: "1px", marginBottom: 2.39 }} />

                  <RequestOverviewData label="Wondros Contact" value={asset.wondros_contact} />
                  <RequestOverviewData label="Contact Email" value={asset.contact_email} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
DetailAsset.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleClickpop: PropTypes.func.isRequired,
  openCopyPopup: PropTypes.PropTypes.func.isRequired,
  anchorCopy: PropTypes.string.isRequired,
};

export default DetailAsset;
