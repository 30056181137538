import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Grid, CircularProgress, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";

// Local imports
import HighLogo from "images/logos/High.webp";
import LowLogo from "images/logos/Low.webp";
import {
  getActiveAssetsByUser,
  getAssetsLegends,
  getStatusAssets,
  getRequestTypesAssets,
} from "api/apiMethods";
import ActiveAssetsFilterMenu from "components/ActiveAssetsFilterMenu/ActiveAssetsFilterMenu";
import PriorityFilter from "components/PriorityFilter/PriorityFilter";
import AssetDetailView from "components/AssetComponent/AssetDetailView";
// import ListItemsLoader from "components/ListItemsLoader/ListItemsLoader";
import Heading from "components/HeadingComponent/Heading";
import DropdownOptionsSort from "components/DropdownOptionsSort/DropdownOptionsSort";
import DropdownOptionsCheckbox from "components/DropdownOptionsCheckbox/DropdownOptionsCheckbox";
import DropdownOptionsRequestTypes from "components/DropdownOptionsRequestTypes/DropdownOptionsRequestTypes";

const options = ["Newest Requests", "Low Priority", "High Priority", "Oldest Requests"];

function Detailview() {
  const [selectedStatus, setSelectedStatus] = useState(
    JSON.parse(sessionStorage.getItem("selectedStatusDetail")) || []
  );
  const [selectedRequestTypes, setSelectedRequestTypes] = useState(
    JSON.parse(sessionStorage.getItem("selectedRequestTypesDetail")) || []
  );
  const [selectedSortValues, setSelectedSortValues] = useState(
    JSON.parse(sessionStorage.getItem("selectedSortDetail")) || []
  );
  const [filterNames, setFilterNames] = useState(
    JSON.parse(sessionStorage.getItem("selectedFiltersDetail")) || []
  );
  const [legendData, setLegendData] = useState([]);
  const [statusDetails, setStatusDetails] = useState(null);
  const [isOpenRequest, setIsOpenRequest] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [filterColumnList, setFilterColumnList] = useState([]);
  const [requestDetails, setRequestDetails] = useState(null);

  const userId = localStorage.getItem("user_id");
  const userPartners = sessionStorage.getItem("partners");
  const activeAssetsDataObj = {
    statusIds: selectedStatus.join(":"),
    requestTypes: selectedRequestTypes.join(":"),
    // partners: "",
    partners: userPartners,
    requester: "",
    userId: Number(userId),
    sort: selectedSortValues.join(","),
  };

  sessionStorage.setItem("selectedFiltersDetail", JSON.stringify(filterNames));
  sessionStorage.setItem("selectedStatusDetail", JSON.stringify(selectedStatus));
  sessionStorage.setItem("selectedRequestTypesDetail", JSON.stringify(selectedRequestTypes));
  sessionStorage.setItem("selectedSortDetail", JSON.stringify(selectedSortValues));

  const { isLoading, data: activeAssets } = useQuery(
    [
      "activeAssetsDetailView",
      selectedStatus,
      selectedRequestTypes,
      selectedSortValues,
      statusDetails,
    ],
    () => getActiveAssetsByUser(activeAssetsDataObj).then((response) => response.data)
  );

  const handleClickStatus = () => {
    setIsOpenStatus((prevState) => !prevState);
  };
  const handleClickRequest = () => {
    setIsOpenRequest((prevState) => !prevState);
  };
  const handleClickSort = () => {
    setIsOpenSort(true);
  };

  const filterRemove = (event, filter) => {
    event.preventDefault();
    setSelectedStatus((prevState) => {
      return prevState.filter((a) => a !== filter.wrike_status_id);
    });
    setFilterNames((prevState) => {
      return prevState.filter((a) => {
        if (typeof filter === "object") {
          return a.wrike_status_id !== filter.wrike_status_id;
        }
        return a !== filter;
      });
    });
    setSelectedSortValues((prevState) => {
      return prevState.filter((a) => a !== filter);
    });
    setSelectedRequestTypes((prevState) => {
      return prevState.filter((a) => a !== filter);
    });
  };

  const lowPriority = () => {
    setSelectedSortValues(["Low Priority"]);
    setFilterNames((prevState) => {
      return prevState.filter(
        (a) =>
          a !== "Status" &&
          a !== "Newest Requests" &&
          a !== "Oldest Requests" &&
          a !== "High Priority" &&
          a !== "Low Priority"
      );
    });
    setFilterNames((prevState) => {
      return prevState.concat("Low Priority");
    });
  };

  const highPriority = () => {
    setSelectedSortValues(["High Priority"]);
    setFilterNames((prevState) => {
      return prevState.filter(
        (a) =>
          a !== "Status" &&
          a !== "Newest Requests" &&
          a !== "Oldest Requests" &&
          a !== "High Priority" &&
          a !== "Low Priority"
      );
    });
    setFilterNames((prevState) => {
      return prevState.concat("High Priority");
    });
  };

  useEffect(() => {
    if (statusDetails == null) {
      getStatusAssets().then((response) => {
        if (response !== false) {
          setStatusDetails(response);
        }
      });
    }
  }, []);

  useEffect(() => {
    getAssetsLegends(userId).then((response) => {
      if (response !== null) {
        setLegendData(response.data);
      }
    });
    getRequestTypesAssets().then((response) => {
      if (response !== false) {
        setRequestDetails(response);
      }
    });
  }, []);

  // const renderListData = ({ index, key, style }) => {
  //   const asset = activeAssets[index];

  //   return (
  //     <div key={key} style={style}>
  //       <AssetDetailView asset={asset} statusValues={statusDetails} />
  //     </div>
  //   );
  // };

  const listItemLoader = () => {
    if (activeAssets?.length <= 0) {
      return (
        <Heading
          title="No Record Available"
          titleStyle="detail-view-container__no-assets-heading"
        />
      );
    }

    return (
      <Box
        // className="detail-view-container__box-body"
        sx={{
          marginTop: "5",
          height: "514px",
          overflowY: "auto",
          minHeight: "250px",
        }}>
        <div className="detail-view-container__scroller">
          {activeAssets &&
            activeAssets.length &&
            activeAssets.map((asset) => {
              return (
                <div className="detail-view-container__list_item">
                  <AssetDetailView asset={asset} statusValues={statusDetails} />
                </div>
              );
            })}
        </div>
        {/* <ListItemsLoader activeAssets={activeAssets} renderData={renderListData} /> */}
      </Box>
    );
  };

  return (
    <Container className="detail-view-container">
      <Grid container>
        <Grid item md={6} lg={4} sm={12}>
          <Typography className="detail-view-container__typography-assets-heading" variant="body">
            {activeAssets !== null ? activeAssets?.length : ""} Active Asset
            {activeAssets !== null && activeAssets?.length === 1 ? "" : "s"}
          </Typography>
        </Grid>

        <Grid item md={6} lg={8} sm={12}>
          <Grid
            container
            spacing={2.8}
            columns={{ md: 3 }}
            className="detail-view-container__align-grid">
            {legendData.map((items) => {
              let tooltip = "0";
              if (items.name === "Intake") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      Intake
                    </Typography>
                    The <b>Intake</b> main status indicates that the request is in one of the
                    following initial processing statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Intake New Request, Pending Partner Intake Info, Received From Partner or On
                      Hold.
                    </Typography>
                  </Typography>
                );
              } else if (items.name === "Under Approval") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      Under Approval
                    </Typography>
                    The <b>Under Approval</b> main status indicates that the request is in one of
                    the following review statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Wondros Review, Wondros Prep for NIH, NIH Comms Review, Program Officer
                      Review, NIH Comms Final Review, Spanish Committee, SAGE, IRB, NIH Language
                      Proofing, or Partner Info.
                    </Typography>
                  </Typography>
                );
              } else if (items.name === "In Production") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      In Production
                    </Typography>
                    The <b>In Production</b> main status indicates that the request is in the
                    following production or review statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Design Production, Pending Partner Info, Spanish Review or Partner Review.
                    </Typography>
                  </Typography>
                );
              } else if (items.name === "Prep Final Files") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      Prep Final Files
                    </Typography>
                    The <b>Prep Final Files</b> main status indicates that the request is in the
                    following final statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Final File Production or Asset QC, Programming, Upload.
                    </Typography>
                  </Typography>
                );
              }
              return (
                <Grid item>
                  <ActiveAssetsFilterMenu
                    dotColor={items.color}
                    title={items.name}
                    tooltip={tooltip}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      {/* current status menu */}
      <Grid container pt={1.25} pb={1.1875}>
        <Grid item md={12} className="filtersNamesContainer">
          {filterNames !== undefined &&
            filterNames.map((filter, index) => (
              <span className="detail-view-container__detail-view-asset-names" id="asset2">
                <Typography
                  className="detail-view-container__typography-font-filter"
                  pl={1}
                  pt={0.5}
                  pb={0.5}
                  pr={1.25}>
                  {filter.name || filter}
                </Typography>{" "}
                <CloseIcon
                  sx={{ width: "20px", height: "20px" }}
                  id={index}
                  onClick={(event) => filterRemove(event, filter)}
                  className="detail-view-container__detail-view-close-icon"
                />
              </span>
            ))}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          width: "102%",
        }}>
        <Grid item md={4} lg={2.1} sm={12} xs={12} pt="19px">
          <Typography variant="h7" className="detail-view-container__typography-current-status">
            CURRENT STATUS
          </Typography>
        </Grid>

        <Grid item md={4} lg={3.9} pt={1.7} pl={2.5} sm={12} xs={12}>
          <Box sx={{ display: "flex" }}>
            <PriorityFilter
              title="High Priority"
              titleStyle="detail-view-container__img-logo-image-high"
              image={HighLogo}
              onClick={highPriority}
              leftSpace={3}
            />
            <PriorityFilter
              titleStyle="detail-view-container__img-logo-image"
              title="Low Priority"
              image={LowLogo}
              onClick={lowPriority}
            />
          </Box>
        </Grid>

        <Grid item md={4} lg={6}>
          <Box
            container
            className="detail-view-container__align-grid"
            mt={3}
            sx={{ display: "flex" }}>
            <DropdownOptionsRequestTypes
              selectedRequestTypes={selectedRequestTypes}
              setSelectedRequestTypes={setSelectedRequestTypes}
              filterNames={filterNames}
              setFilterNames={setFilterNames}
              handleClickRequest={handleClickRequest}
              isOpenRequest={isOpenRequest}
              requestDetails={requestDetails}
            />
            <Box className="detail-view-container__grid_sort-border" md={0.22} />

            <DropdownOptionsCheckbox
              checkBoxData={statusDetails}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              filterNames={filterNames}
              setFilterNames={setFilterNames}
              setFilterColumnList={setFilterColumnList}
              filterColumnList={filterColumnList}
              handleClickStatus={handleClickStatus}
              isOpenStatus={isOpenStatus}
            />
            <Box className="detail-view-container__grid_sort-border" md={0.22} />

            <DropdownOptionsSort
              selectedSortValues={selectedSortValues}
              setSelectedSortValues={setSelectedSortValues}
              setFilterNames={setFilterNames}
              data={options}
              handleClickSort={handleClickSort}
              isOpenSort={isOpenSort}
              setIsOpenSort={setIsOpenSort}
              filterNames={filterNames}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ width: "100%", height: "1px" }} />
      {isLoading && (
        <div style={{ height: 200 }}>
          <CircularProgress
            size={100}
            sx={{
              color: "#262262",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
              zIndex: 29,
            }}
          />
        </div>
      )}
      {!isLoading && listItemLoader()}
    </Container>
  );
}

export default Detailview;
